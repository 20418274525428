


















































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { FindModalidadeDeVendaUseCase, SaveModalidadeDeVendaUseCase } from '@/usecases'
import { ModalidadeDeVenda } from '@/models'
import { maiorOuIgualAZero, obrigatorio } from '@/shareds/regras-de-form'

@Component
export default class DialogoDeEdicaoDeModalidadeDeVenda extends Vue {
	@Prop({ type: String }) lojaId?: string
	modalidade: ModalidadeDeVenda | null = null
	mostra = false
	salvando = false
	saveModalidadeDeVendaUseCase = new SaveModalidadeDeVendaUseCase()
	findModalidadeDeVendaUseCase = new FindModalidadeDeVendaUseCase()
	sequenciais: number[] = []
	tiposDeModalidade = [
		'Presencial',
		'Devolução',
		'Demonstração',
		'Ecommerce',
		'Link',
		'Outro',
	]

	obrigatorio = obrigatorio
	maiorOuIgualAZero = maiorOuIgualAZero

	@Watch('mostra')
	async buscarSequenciaisValidos() {
		if (!this.mostra) return;

		try {
			this.sequenciais = await this.findModalidadeDeVendaUseCase.findSequenciaisValidos(this.lojaId)
			const listaDeSequenciais = this.modalidade?.sequencial

			if (listaDeSequenciais) {
				const sequenciaisCount = this.sequenciais.length
				let position = 0;

				while (position < sequenciaisCount && listaDeSequenciais > this.sequenciais[position]) {
					position++;
				}

				this.sequenciais.splice(position, 0, listaDeSequenciais)
			}
		} catch (e) {
			AlertModule.setError(e)
		}
	}


	mostrar(modalidade) {
		this.mostra = true
		this.modalidade = modalidade
	}

	esconder() {
		this.mostra = false
		this.$emit('fecharDialogo')
	}

	async salvar() {
		if (!this.modalidade) return
		if (!this.modalidade.origem) return
		if (!this.lojaId) return 
		if (this.modalidade.sequencial !== 0 && !this.modalidade.sequencial) return AlertModule.setError('Selecione um sequencial')
		try {
			this.salvando = true
			const modalidade = this.modalidade
			
			const tagSalva = this.modalidade.id
				? await this.saveModalidadeDeVendaUseCase.update(modalidade)
				: await this.saveModalidadeDeVendaUseCase.create(this.lojaId, modalidade)
			
			this.$emit('confirmado', tagSalva)
			
			this.esconder()
			this.modalidade = null
			AlertModule.setSuccess('Modalidade salva com sucesso')
		} catch (error) {
			AlertModule.setError(error)
		} finally {
			this.salvando = false
		}
	}
}
