









































import { Vue, Component, Ref, Watch } from 'vue-property-decorator'
import DataTableDeCrud from '@/components/ui/DataTableDeCrud.vue'
import Axios, { CancelTokenSource } from 'axios'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import SeletorDeLojasDoUsuario from '@/components/loja/SeletorDeLojasDoUsuario.vue'
import { FindLojaUseCase, FindModalidadeDeVendaUseCase, SaveModalidadeDeVendaUseCase } from '@/usecases'
import DialogoDeEdicaoDeModalidadeDeVenda from '@/components/venda/DialogoDeEdicaoDeModalidadeDeVenda.vue'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { ModalidadeDeVenda, Loja } from '@/models'
import { DataOptions } from 'vuetify'
import Confirmacao from '@/components/ui/Confirmacao.vue'

@Component({
	components: {
		SeletorDeLojasDoUsuario,
		DataTableDeCrud,
		DialogoDeEdicaoDeModalidadeDeVenda,
		Confirmacao,
	},
})

export default class TelaDeModalidadesDeVenda extends Vue {
	@Ref() dialogoDeEdicaoDeModalidadeDeVenda!: DialogoDeEdicaoDeModalidadeDeVenda
	@Ref() confirmacao!: Confirmacao

	headers = [
		{ text: 'Sequencial', value: 'sequencial' },
		{ text: 'Modalidade', value: 'modalidade' },
		{ text: 'Origem', value: 'origem' },
	]

	modalidades: ModalidadeDeVenda[] | [] = [] 
	idsDasLojas: string[] = []
	lojaId: string | null = null
	loja: Loja | null = null
	
	findLojaUseCase = new FindLojaUseCase()
	findModalidadeDeVendaUseCase = new FindModalidadeDeVendaUseCase()
	saveModalidadeDeVendaUseCase = new SaveModalidadeDeVendaUseCase()
	
	carregando = false

	paginavel: DataOptions = {
		page: 0,
		itemsPerPage: 5,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	totalRegistros = -1
	cancelToken: CancelTokenSource | null = null

	modalidadeIdSelecionada: string | null = null

	abrirDialogoDeCriarModalidade() {
		try {
			if(!this.podeCriarModalidade) 
				throw new Error('Necessário permissão para criar')
			
			const modalidade = {id:""}
			this.dialogoDeEdicaoDeModalidadeDeVenda.mostrar(modalidade)
		} catch(error) {
			AlertModule.setError(error)
		}
	}

	abrirDialogoDeEditarModalidade(indice: number) {
		const modalidade = this.modalidades[indice]
		
		this.dialogoDeEdicaoDeModalidadeDeVenda.mostrar(modalidade)
	}

	async removerModalidade(){
		try {
			
			if(!this.podeRemoverModalidade) 
				throw new Error('Necessário permissão para remover')
			if(this.modalidadeIdSelecionada == null)
				return

			const itemId = this.modalidades[this.modalidadeIdSelecionada].id
			
			await this.saveModalidadeDeVendaUseCase.remove(itemId)
			
			AlertModule.setSuccess('Modalidade excluida com sucesso!')
		} catch(error) {
			AlertModule.setError(error)
		} finally {
			this.carregar()
		}
	}

	async carregar() {
		if (!this.lojaId) return
		if (this.cancelToken) this.cancelToken.cancel()
		

		try {
			this.carregando = true
			this.cancelToken = Axios.CancelToken.source()

			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}

			const parametros = { 
				lojaId: this.lojaId,
				page: this.paginavel.page - 1,
				size: this.paginavel.itemsPerPage,
			}
			
			const pagina = await this.findModalidadeDeVendaUseCase.findAll(parametros, axiosConfig)
			
			this.modalidades = pagina.content
			
			this.totalRegistros = pagina.totalElements
			
			return pagina
		} catch (error) {
			AlertModule.setError(error)
		} finally {
			this.carregando = false
		}
	}

	get podeCriarModalidade() {
		return UserLoginStore.temAutorizacaoPorOperacaoERecurso('criar', 'modalidades-de-venda')
	}

	get podeEditarModalidade() {
		return UserLoginStore.temAutorizacaoPorOperacaoERecurso('alterar','modalidades-de-venda')
	}

	get podeRemoverModalidade() {
		return UserLoginStore.temAutorizacaoPorOperacaoERecurso('deletar','modalidades-de-venda')
	}

	async exibeConfirmacao(modalidadeId: string){
		this.modalidadeIdSelecionada = modalidadeId
		this.confirmacao.mostrar()
	}

	@Watch('paginavel', { deep: true })
	onChangePaginacao = this.carregar

	@Watch('lojaId')
	onChangeLoja = this.carregar()
	

}
